@import "./scss-variables";
@import "sass-rem";

.titeFeuille {
	text-align: left;
	margin-left: 5vw;
	svg {
		width: 28vw;
		height: auto;
	}
}

.contForm {
	background-image: url("../images/formulaireBG.webp");
	@media screen and(min-width:1224px) {
		background-image: url("../images/bgForm.webp");
		height: 700px;
	}
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	height: 700px;
	margin-bottom: 30px;
	form {
		display: flex;
		flex-direction: column;
		.contTitre {
			margin-top: 130px;

			h1 {
				font-weight: 600;
				letter-spacing: 0.05em;
				font-family: $work;
				font-size: rem(28px);
				color: #fff;
			}
		}

		.contInputs {
			display: flex;
			flex-direction: column;
			width: 80%;
			// align-items: center;
			margin-left: auto;
			margin-right: auto;
			background-color: rgba(255, 255, 255, 0.671);
			padding-bottom: 50px;
			input,
			textarea {
				margin-left: auto;
				margin-right: auto;
				border-radius: 0;
				border: none;
				margin-top: 5px;
				margin-bottom: 5px;
				@media screen and(min-width:1224px) {
					margin-bottom: 25px;
				}
				width: 95%;
			}
			input {
				height: 50px;
				padding-left: 5vw;
				&::placeholder {
					font-style: italic;
					font-weight: bold;
					opacity: 1;
					color: #000;
					transition: 300ms ease;
				}
				&:focus-visible {
					outline: none;
					&::placeholder {
						opacity: 0;
					}
				}
			}
			input:first-child {
				margin-top: 10px;
			}
			textarea {
				margin-bottom: 10px;
				padding-left: 5vw;
				resize: none;
				height: 150px;
				&::placeholder {
					opacity: 1;
					color: #000;
					font-weight: bold;
					font-style: italic;
				}
			}
			.merci {
				margin-top: 50px;
			}
			button {
				width: 50%;
				background-color: #fff;
				height: 50px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 20px;
				color: $rouge-nav;
				font-weight: bold;
				font-size: rem(24px);
				border: none;
				font-family: $work;
				@media screen and(min-width:1224px) {
					width: 20%;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						margin-top: 5px;
						// margin-bottom: auto;
						margin-left: 20px;
						width: 15%;
					}
				}
				// box-shadow: 3px 3px black;
			}
		}
		.contInput2 {
			display: flex;
			justify-content: center;
			align-items: center;
			input {
				// padding: 0;
				width: 45%;
			}
			textarea {
				&::placeholder {
					font-style: italic;
				}
			}
			.email {
				margin-top: 10px;
			}
		}
	}
}
