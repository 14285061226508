@import "./scss-variables.scss";
@import "sass-rem";
.contNavD {
	background-color: $rouge-nav;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	width: 100%;
	z-index: 55;
	height: 10vh;
	.logo {
		margin-right: auto;
		margin-left: 1vw;
		margin-top: auto;
		margin-bottom: auto;
		svg {
			margin-top: auto;
			margin-bottom: auto;
			width: 15vw;
			height: 10vh;
		}
	}
	.contNavGauche {
		// margin-right: 2vw;
		display: flex;
		// justify-content: space-between;
		align-items: center;
		.contLinks {
			display: flex;
			align-items: center;
			ul {
				margin-bottom: auto;
				margin-top: auto;
				display: flex;
				align-items: center;
				list-style: none;
				li {
					cursor: pointer;
					margin-left: 5vw;
					font-family: $work;
					font-weight: 700;
					color: #fff;
					font-size: rem(16px);
					margin-top: auto;
					margin-bottom: auto;
				}
			}
		}
	}
	.contPhone {
		margin-left: 2.5vw;
		background-color: $vert-nav;
		display: flex;
		width: 10vw;
		height: inherit;
		align-items: center;
		justify-content: center; //padding-top: 3.6vh;
		//padding-bottom: 3.6vh;
		//padding-left: 2vw;
		//padding-right: 2vw;
		a {
			color: #fff;
			font-family: $work;
			font-weight: 700;
			text-decoration: none;
			font-size: rem(18px);
			&:visited {
				color: #fff;
			}
		}
	}
}
.contNav {
	background-color: #ed0213;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	height: 75px;
	color: #fff;
	overflow: hidden;
	// margin-bottom: 50px;
	.logo {
		width: 160px;
		svg {
			width: 170px;
			height: 57px;
		}
	}
}
.contHam {
	width: 63px;
	height: 63px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	z-index: 5;
	span {
		background: #fff;
		width: 20px;
		margin-bottom: 5px;
		height: 2px;
	}

	span:first-child {
		transform: translateY(0px) translateX(0px) rotate(0deg);
		animation: xx1 200ms ease forwards;
	}
	span:last-child {
		transform: translateY(0px) translateX(0px) rotate(0deg);
		animation: xx2 200ms ease forwards;
	}
}
@keyframes xx1 {
	from {
		transform: translateY(10px) translateX(0px) rotate(45deg);
	}
	to {
		transform: translateY(0px) translateX(0px) rotate(0deg);
	}
}
@keyframes xx2 {
	from {
		transform: translateY(-3px) translateX(0px) rotate(-45deg);
	}
	to {
		transform: translateY(0px) translateX(0px) rotate(0deg);
	}
}

nav {
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	background-color: $vert-nav;
	padding-top: 10px;
	height: 100vh;
	width: fit-content;
	margin-left: auto;
	z-index: 2;

	ul {
		padding-top: 75px;
		margin-top: 0;
		list-style: none;
		text-align: right;
		color: #fff;
		width: fit-content;
		margin-left: auto;
		li {
			margin-right: 15px;
			margin-left: auto;
			padding: 5px;
			margin-bottom: 5px;
			width: fit-content;
			text-align: right;
			font-family: $barlow;
			letter-spacing: 0.05em;
			font-size: rem(22px);
			font-weight: 200;
		}
		.ligne {
			padding: 0;
			margin-left: auto;
			// margin-right: 15px;
			background-color: #fff;
			width: 35vw;
			height: 1px;
		}
	}
}
.openNav {
	position: absolute;

	top: 0px;
	overflow: hidden;
	animation: slideNavOpen forwards 200ms;
}
.closeNav {
	position: relative;
	top: -75px;
	overflow: hidden;
	animation: slideNavClose forwards 200ms;
}

@keyframes slideNavOpen {
	from {
		right: -50vw;
	}
	to {
		right: 0;
	}
}
@keyframes slideNavClose {
	from {
		right: 0;
		// width: 0vw;
	}
	to {
		// width: 50vw;
		right: -100vw;
	}
}

.hamClicked {
	span:nth-child(2) {
		visibility: hidden;
	}

	span:first-child {
		transform: translateY(0px) translateX(0px) rotate(0deg);
		animation: x1 200ms ease forwards;
	}
	span:last-child {
		transform: translateY(0px) translateX(0px) rotate(0deg);
		animation: x2 200ms ease forwards;
	}
}
@keyframes x1 {
	from {
		transform: translateY(0px) translateX(0px) rotate(0deg);
	}
	to {
		transform: translateY(10px) translateX(0px) rotate(45deg);
	}
}
@keyframes x2 {
	from {
		transform: translateY(0px) translateX(0px) rotate(0deg);
	}
	to {
		transform: translateY(-3px) translateX(0px) rotate(-45deg);
	}
}
