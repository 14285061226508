@import "./scss-variables";
@import "sass-rem";

.contNosReas {
	margin-top: -15vh;
	width: 85vw;
	margin-left: auto;
	margin-right: auto;
	.titre {
		text-align: left;
		// margin-left: 2vw;
		h1 {
			font-weight: bold;
			font-size: rem(24px);
			letter-spacing: 0.05em;
			text-align: left;
			span {
				font-size: rem(36px);
				color: $vert-nav;
			}
		}
		p {
			font-size: rem(17px);
			letter-spacing: 0.05em;
		}
	}
	.petiteDesc {
		letter-spacing: 0.05em;
		text-align: justify;
		font-family: $work;
		font-weight: 300;
		font-size: rem(20px);
	}
	.contCTAD {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 20px;
		p {
			color: $vert-nav;
			font-weight: 600;
			font-size: rem(24px);
			margin-top: 0;
			font-family: $work;
		}
		img {
			margin-left: 15px;
			width: 5%;
			// margin-top: 22px;
			margin-bottom: auto;
		}
	}

	.contCTA {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 20px;
		p {
			color: $vert-nav;
			font-weight: 600;
			font-size: rem(24px);
			margin-top: 0;
			font-family: $work;
		}
		img {
			margin-left: 15px;
			width: 15%;
			// margin-top: 22px;
			margin-bottom: auto;
		}
	}
}
.contNosReasD {
	width: 60vw;
	// display: flex;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 150px;
	svg {
		position: relative;
		width: 10vw;
		left: -40vw;
		top: 150px;
		// right: 100%;
		height: fit-content;
	}
	.titre {
		display: flex;
		align-items: center;
		margin-bottom: 50px;
		p {
			// margin-top: auto;
			margin-left: 5vw;
			font-family: $dancing;
			font-size: rem(28px);
			font-weight: bold;
		}
	}
}
