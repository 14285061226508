.modalBackground {
	position: absolute;
	z-index: 88;

	background-color: rgba(243, 230, 230, 0.931);
	.modalContainer {
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.575);
		animation: arrive forwards ease 350ms;
		div {
			// background-color: rgba(0, 0, 0, 0.575);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			// overflow: hidden;
			z-index: 88;
			position: relative;
			top: 10vh;
			left: 70vw;
			height: fit-content;
			width: 50px;
			// right: 0;
			background-color: transparent;
			border: none;
			font-size: 35px;
			color: #fff;
			span {
				width: 60px;
				height: 2px;
				background-color: #fff;
				margin-bottom: 5px;
			}
			span:first-child {
				transform: translateY(0px) translateX(0px) rotate(0deg);
				animation: xx1 200ms ease forwards;
			}
			span:last-child {
				transform: translateY(0px) translateX(0px) rotate(0deg);
				animation: xx2 200ms ease forwards;
			}
		}
		img {
			@media screen and(min-width:1224px) {
				top: 0;
			}
			overflow: visible;
			position: relative;
			top: 16vh;
			margin-top: auto;
			z-index: 32;
			max-width: 100%;
			max-height: 100%;
			// height: inherit;
		}
	}
}
@keyframes arrive {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes xx1 {
	from {
		transform: translateY(0px) translateX(0px) rotate(0deg);
	}
	to {
		transform: translateY(10px) translateX(0px) rotate(45deg);
	}
}
@keyframes xx2 {
	from {
		transform: translateY(0px) translateX(0px) rotate(0deg);
	}
	to {
		transform: translateY(3px) translateX(0px) rotate(-45deg);
	}
}
