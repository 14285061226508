@import "./scss-variables";
@import "sass-rem";
.contCarou {
	margin-left: auto;
	margin-right: auto;
	// overflow: scroll;
	// position: relative;
	display: flex;
	justify-content: center;
	.contImg {
		display: flex;
		justify-content: center;
		width: fit-content;
	}
	img {
		// width: 100%;
		height: 40vh;
		margin-left: 15px;
		margin-right: 15px;
	}
	svg {
		position: relative;
		right: 2vw;
		// top: 2150px;
	}
}

.contCarouPerso .laTiteImage {
	display: flex;
	justify-content: flex-start;
	margin-left: 1vw;
	// margin-right: 0;
	svg {
		width: 25vw;
		height: fit-content;
	}
}

.noir {
	background-color: #000;
	height: 40vh;
}
.carousel {
	height: 500px;
}
.carouselItem {
	img {
		height: 50px;
	}
	.img2 {
		height: 100vh;
	}
}

.fadeCarou {
	// animation-play-state: initial;
	animation: bande-fade 200ms backwards;
}
@keyframes bande-fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.imgDeSwiper {
	max-width: 100%;
	height: 50vh;
}
// .contCarouPerso {
// 	width: 90vw;
// 	height: fit-content;
// 	display: flex;
// 	justify-content: center;
// 	margin-left: auto;
// 	margin-right: auto;
// 	overflow: hidden;

// 	.prevBtn,
// 	.nextBtn {
// 		margin-top: auto;
// 		margin-bottom: auto;
// 		margin-left: auto;
// 		margin-right: auto;
// 		cursor: pointer;
// 	}

// 	.prevBtn {
// 		transform: scaleX(-1);
// 	}

// 	.fenetre {
// 		width: 60vw;
// 		display: flex;
// 		overflow: hidden;
// 		height: 400px;
// 		.bande {
// 			display: flex;
// 			height: inherit;

// 			.contInnerBande {
// 				width: 60vw;
// 				height: auto;
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;
// 				// overflow: hidden;
// 			}
// 			// flex-wrap: nowrap;
// 			// width: 100%;
// 			img {
// 				max-width: 100%;
// 				max-height: 100%;
// 				margin: auto;
// 			}
// 		}
// 	}
// }

// @import "../../node_modules/bootstrap/scss/bootstrap.scss";
