@import "./scss-variables";
@import "sass-rem";

.contCartesD {
	// display: flex;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 5vh 3vw;

	width: 80vw;
	margin-left: auto;
	margin-right: auto;

	.carteD {
		// width: 33%;
		height: 40vh;
		overflow: hidden;
		// margin-bottom: 20px;
		&:nth-child(1) {
			background-image: url("../images/carte1.webp");
			background-size: cover;
			background-position: center;
		}
		&:nth-child(2) {
			background-image: url("../images/carte2.webp");
			background-size: cover;
			background-position: center;
		}
		&:nth-child(3) {
			background-image: url("../images/carte3.webp");
			background-color: red;
			background-size: cover;
			background-position: center;
		}
		&:nth-child(4) {
			background-color: red;
			background-image: url("../images/carte4.webp");
			background-size: cover;
			background-position: center;
		}
		&:nth-child(5) {
			background-image: url("../images/carte5.webp");
			background-color: red;
			background-size: cover;
			background-position: center;
		}
		&:nth-child(6) {
			background-image: url("../images/carte6.webp");
			background-color: red;
			background-size: cover;
			background-position: center;
		}
		.carteCaption {
			position: relative;
			// left: 100vw;
			display: flex;
			// width: 70vw;
			// height: in;
			cursor: default;

			justify-content: center;
			align-items: flex-end;
			margin-left: auto;
			width: 70%;
			background-color: $vert-nav;
			top: calc(40vh - 50px);
			bottom: 0;
			p {
				height: 50px;
				// width: fit-content;
				// padding-left: 15px;
				// padding-right: 15px;
				display: flex;
				align-items: center;
				// margin-top: auto;
				// vertical-align: middle;
				margin-bottom: 0;
				color: #fff;
				font-family: $work;
				font-weight: 300;
				letter-spacing: 0.05em;
			}
		}
	}
}
.contCartes {
	height: fit-content;
	.chapeau {
		display: flex;
		height: fit-content;

		position: relative;
		width: fit-content;
		top: -100px;
		z-index: 0;
		left: 15vw;

		svg {
			width: 20vw;
		}
	}

	.carte {
		position: relative;
		top: -150px;
		z-index: 1;
		width: 100vw;
		height: 30vh;
		@media screen and(min-height:1024px) {
			height: 50vh;
		}
		margin-bottom: 25px;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

		&:nth-child(2) {
			background-image: url("../images/carte1.webp");
			background-position: center;
			background-size: cover;
		}
		&:nth-child(3) {
			background-image: url("../images/carte2.webp");
			background-position: center;
			background-size: cover;
		}
		&:nth-child(4) {
			background-image: url("../images/carte3.webp");
			background-position: center;
			background-size: cover;
		}
		&:nth-child(5) {
			background-image: url("../images/carte4.webp");
			background-position: center;
			background-size: cover;
		}
		&:nth-child(6) {
			background-image: url("../images/carte5.webp");
			background-position: center;
			background-size: cover;
		}
		&:nth-child(7) {
			background-image: url("../images/carte6.webp");
			background-position: center;
			background-size: cover;
		}
		.contCaption {
			// margin-left: auto;
			// margin-right: auto;
			position: relative;
			// left: 100vw;
			display: flex;
			width: 70vw;
			justify-content: center;
			align-items: center;
			background-color: $vert-nav;
			p {
				// margin: ;
				color: #fff;
				margin-top: 0;
				margin-bottom: 0;
				padding-top: 10px;
				padding-bottom: 10px;
				font-size: rem(18.8px);
				font-weight: 300;
				font-family: $work;
			}
		}
	}
}
