.appear {
	animation: appear 400ms forwards;
}
.disappear {
	animation: disappear 400ms forwards;
}
@keyframes appear {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes disappear {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
