@import "./scss-variables";
@import "sass-rem";

.contTem {
	margin-top: 20px;
	.titre {
		text-align: left;
		margin-left: 5vw;
		h1 {
			margin-bottom: 0;
			font-family: $work;
			font-weight: 600;
			letter-spacing: 0.05em;
			span {
				&:nth-child(1) {
					// color: red;
					color: black;
					font-size: rem(24px);
				}
				&:nth-child(2) {
					span {
						color: $vert-nav;
						font-family: $work;
						font-weight: 600;
						font-size: rem(30px);
					}
				}
				&:nth-child(2) {
					font-size: rem(24px);
					font-weight: 600;
				}

				color: $vert-nav;
			}
		}
	}
	.image {
		text-align: left;
		margin-left: 5vw;
		margin-top: 30px;
		height: fit-content;
		svg {
			width: 30vw;
			height: 50px;
		}
	}

	.contTemoins {
		width: 90vw;
		border: 2px black solid;
		border-radius: 25px;
		padding: 30px;
		margin-top: 20px;
		margin-left: auto;
		margin-bottom: 20px;
		margin-right: auto;
		.comment {
			display: flex;
			img {
				// display: inline-block;
				width: 50px;
				height: 50px;
				border-radius: 5px;
			}
			p {
				margin: 10px;
				margin-top: 0;
				text-align: left;
				font-weight: bold;
				margin-left: 5vw;
				width: 100%;
				font-size: rem(13px);
				line-height: 15px;
				span {
					opacity: 0.8;
				}
			}
		}
	}
}
.feuilleD {
	position: relative;
	top: 100px;
	left: 75%;
	// right: 5vw;
	width: 20vw;
	svg {
		width: 15vw;
		height: auto;
	}
}

.contTemD {
	.comment {
		margin-bottom: 50px;
		p {
			margin-left: 0vw;
		}
	}
	.titreD {
		text-align: left;
		margin-left: 20vw;
		span {
			&:nth-child(1) {
				// color: red;
				color: black;
				font-size: rem(28px);
			}
			&:nth-child(2) {
				span {
					color: $vert-nav;
					font-family: $work;
					font-weight: 600;
					font-size: rem(32px);
				}
			}
			&:nth-child(2) {
				font-size: rem(28px);
				font-weight: 600;
			}

			color: $vert-nav;
		}
	}
	.image {
		position: relative;
		// top: 350px;
		svg {
			width: 15vw;
			height: auto;
		}
	}
	.contTemoinsD {
		width: 50vw;
	}
}
