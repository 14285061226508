@import "./scss-variables";
@import "sass-rem";
.contFooterD {
	color: #fff;
	display: flex;
	flex-direction: row-reverse;
	width: 100vw;
	height: 25vh;
	font-family: $work;
	.footerVert {
		background-color: $vert-nav;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		width: 50%;
		padding-left: 2vw;
		a {
			img {
				width: 2vw;
				margin-left: 2vw;
				margin-right: 2vw;
				// margin-bottom: auto;
				// margin-top: 0;
			}
		}
		.adresse {
			margin-left: 5vw;
			text-align: left;
			// display: flex;
		}
		.email {
			text-align: left;
			a {
				text-decoration: none;
				color: #fff;
			}
		}
	}
	.footerRouge {
		display: flex;
		background-color: $rouge-nav;
		flex-direction: row;
		width: 50%;
		justify-content: flex-start;
		padding-left: 5vw;
		align-items: center;
		text-align: left;
		.titre {
			display: flex;
			h1 {
				text-transform: uppercase;
				font-size: rem(24px);
				font-weight: 700;
				margin-right: 2vw;
				width: fit-content;
			}
			a {
				color: #fff;
				&:visited {
					color: #fff;
				}
			}
		}
	}
}

.contFooter {
	color: #fff;
	.footerVert {
		background-color: $vert-nav;
		.adresse {
			padding-top: 30px;
			p {
				font-weight: 200;
				font-size: rem(20px);
				font-family: $work;
			}
		}
		.email {
			display: flex;
			flex-direction: column;
			padding-bottom: 25px;
			p {
				margin-bottom: 0;
				font-family: $work;
				font-weight: 300;
				font-size: rem(20px);
			}
			a {
				font-family: $work;
				font-weight: 300;
				font-size: rem(20px);
				text-decoration: none;
				color: #fff;
				&:visited {
					color: #fff;
					text-decoration: none;
				}
			}
			a:first-child {
			}
			a:last-child {
				margin-top: 20px;
				img {
					width: 40px;
				}
			}
		}
	}
	.footerRouge {
		background-color: $rouge-nav;
		padding-bottom: 30px;
		.titre {
			h1 {
				text-align: left;
				margin-left: 5vw;
				padding-top: 20px;
				font-size: rem(23px);
				font-weight: 700;
				font-family: $work;
				text-transform: uppercase;
			}
			a {
				display: block;
				color: #fff;
				text-align: left;
				margin-left: 5vw;
				margin-right: auto;
				font-weight: 300;
				// line-height: 15px;
				// text-decoration: underline;
				font-family: $work;
				&:visited {
					color: #fff;
				}
			}
		}
	}
}
