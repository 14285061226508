@import "./scss-variables";

@import "sass-rem";
.heroD {
	background-image: url("../images/bgHeroD.jpeg");
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	padding-top: 25vh;
	// padding-bottom: 10vh;
	height: 100vh;

	// background-clip: border-box;
	background-position: center;
	.contTextHeroD {
		background-color: rgba(255, 255, 255, 0.65);
		width: 20%;
		margin-left: 60vw;
		margin-right: auto;
		margin-top: 5vh;
		margin-bottom: auto;
		.petitTitreD {
			width: 90%;
			margin-left: auto;
			margin-right: auto;

			margin: 0;
			text-align: center;
			font-weight: normal;
			color: #fff;
			font-size: rem(26px);
			font-family: $dancing;
			// font-style: normal;
			margin-bottom: 0;
			position: relative;
			top: -10px;
			left: 1vw;
			font-weight: bold;
			background-color: $rouge-nav;
		}
		.premierh1D {
			font-family: $work;
			font-weight: 700;
			text-transform: uppercase;
			margin-top: 20px;
			font-size: rem(30px);
			text-align: left;
			display: block;
			margin-left: 3vw;
			// margin-right: auto;
		}
		.deuxiemeh1D {
			display: block;
			// margin-left: auto;
			margin-right: auto;
			margin-left: 3vw;
			text-align: left;
			font-family: $work;
			font-weight: 700;
			font-size: rem(28px);
			color: $rouge-nav;
			span {
				font-family: $dancing;
				font-weight: 400;
				font-size: rem(30px);
			}
		}
		.quote1,
		.quote2 {
			width: fit-content;
			display: flex;
			// margin-right: 1"00vw;
			// margin-left: 0;"

			// flex-direction: column;
			// justify-content: flex-start;
			// align-items: center;
			// margin: 0;
			margin-top: 20px;
			svg {
				width: 3.5vw;
				height: fit-content;
				color: #000;
			}
		}
		.quote1 {
			// margin-top: 5px;
			margin-right: auto;
			margin-left: 2vw;
			margin-top: 30px;
		}
		.quote2 {
			margin-top: 0;
			margin-right: 2vw;
			margin-left: auto;
			margin-bottom: 20px;
			svg {
				transform: rotate(180deg);
			}
		}
		p {
			font-family: $work;
			font-weight: 700;
			text-align: left;
			margin-left: 2vw;
			margin-bottom: 0;

			&:nth-child(5) {
				margin-top: 20px;
				font-size: rem(24px);
			}
			&:nth-child(6) {
				// margin-bottom: 5px;
				font-size: rem(26px);
			}
			&:nth-child(7) {
				font-size: rem(24px);
				// margin-bottom: 5px;
			}
			&:nth-child(8) {
				font-size: rem(28px);
				// margin-bottom: 5px;
			}
		}
		.demandeDevis {
			background-color: #fff;
			color: $rouge-nav;
			position: relative;
			width: 80%;
			// text-align: center;
			margin-left: auto;
			margin-right: auto;
			top: 10px;
			font-weight: 700;
			font-size: rem(20px);
		}
	}
}
.hero {
	// background-color: rgb(32, 174, 187);
	background-image: url("../images/3a257b_91e1e193ac8c498faec1618bd4585a0d_mv2.webp");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 70vh;
	@media screen and(max-height:690px) {
		height: 80vh;
	}
	@media screen and(max-height:570px) {
		height: 90vh;
	}
	// width: 80%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	padding-top: 25px;
	overflow: visible;
	h1 {
		margin-top: 0;
		margin-bottom: 0;
	}
	.contText {
		// position: relative;
		// top: auto;
		// bottom: auto;
		// display: flex;
		// flex-direction: column;
		// justify-content: center;
		// align-items: center;
		background-color: rgba(255, 255, 255, 0.65);
		width: 85%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 5vh;
		margin-bottom: auto;
		// padding-top: 30px;
		.petitTitre {
			width: 90%;
			margin-left: auto;
			margin-right: auto;

			h1 {
				margin: 0;
				text-align: center;
				font-weight: normal;
				color: #fff;
				font-size: rem(26px);
				font-family: $dancing;
				// font-style: normal;
				margin-bottom: 0;
			}
			position: relative;
			top: -10px;
			font-weight: bold;
			background-color: $rouge-nav;
			// font-style: italic;
		}
		.cont2eTitre {
			h1 {
				text-transform: uppercase;
				span {
					color: $rouge-nav;
					font-family: $work;
					font-weight: 500;
					&:last-child {
						text-transform: none;
						font-weight: 400;
						// font-style: italic;
						font-family: $dancing;
						font-size: rem(30px);
					}
				}
			}
		}
		.contQuote {
			display: flex;
			flex-direction: column;
			align-items: center;
			.quote1,
			.quote2 {
				width: fit-content;
				display: flex;
				// margin-right: 1"00vw;
				// margin-left: 0;"

				// flex-direction: column;
				// justify-content: flex-start;
				// align-items: center;
				margin: 0;

				svg {
					width: 10vw;
					height: fit-content;
					color: #000;
				}
			}
			.quote1 {
				margin-top: 5px;
				margin-right: auto;
				margin-left: 12vw;
			}
			.quote2 {
				margin-top: 0;
				margin-right: 12vw;
				margin-left: auto;
				svg {
					transform: rotate(180deg);
				}
			}
			p {
				display: inline-block;
				// justify-content: center;
				margin-left: auto;
				letter-spacing: 0.05em;
				margin-right: auto;
				width: fit-content;
				text-align: left;
				margin-top: 5px;
				margin-bottom: 5px;
				font-family: $work;
				font-weight: 600;
				font-size: rem(30px);
			}
		}
		.demandeDevis {
			position: relative;
			top: 25px;
			display: flex;
			flex-direction: row;
			margin-left: auto;
			margin-right: auto;
			height: 60px;
			background-color: #fff;
			width: 90%;

			p {
				font-weight: bold;
				// padding-top: 5px;
				margin-top: auto;
				margin-bottom: auto;

				margin-left: auto;
				margin-right: auto;
				height: fit-content;
				font-size: rem(25px);
				color: $rouge-nav;
			}
			.contTicket {
				visibility: hidden;
				position: relative;
				left: 14.5px;
				.tiBoute1,
				.tiBoute2,
				.tiBoute {
					margin: 0;
					width: 15px;
					height: 12.1px;
					// border: 5px solid transparent;
					background-color: #fff;
				}
				.tiBoute1 {
					clip-path: polygon(0 0, 0% 100%, 100% 0);
				}
				.tiBoute2 {
					clip-path: polygon(0 100%, 100% 100%, 0 0);
				}
				.tiBoute {
					// width: 10px;
					clip-path: polygon(0 0, 0% 100%, 100% 50%);

					// margin-bottom: 5px;
				}
			}
		}
	}
}
